<template>
  <div class="flex flex-col gap-4">
    <h1>Cash in omloop</h1>

    <p class="italic">
      Een overzicht van cash ontvangsten die nog in omloop zijn en nog niet afgerekend zijn, per chauffeur.
    </p>

    <UITabs class-button="block" class="flex w-full" :tabs="tabs" v-model="tab" />

    <div v-for="(items, index) in Object.values(filteredItems)" :key="index">
      <h2 class="mb-2 mt-4">{{ index === 0 ? 'Eigen chauffeurs' : 'Onderaannemers' }}</h2>
      <UITableResponsive :items="items" :headers="['chauffeur', 'totaal']" class="text-center" :suffix-headers="['Acties']">
        <template v-slot:item-chauffeur="{ item }">
          {{ store.getters.chauffeur(item.user_id)?.label || '?' }}
        </template>
        <template v-slot:item-totaal="{ item }">
          &euro;&nbsp;{{ formatPrice(item.totaal) }}
        </template>
        <template v-slot:item-Acties="{ item }">
          <router-link v-if="item.user_id > 0" :to="`/administratie/afrekenen?chauffeur=${item.user_id}`" class="mr-2 btn info small">
            <i class="fas fa-book-reader"></i>
            Naar afrekening
          </router-link>
        </template>
      </UITableResponsive>
    </div>

  </div>
</template>

<script setup>
import { ref, inject, onMounted, computed } from 'vue'
import { useStore } from 'vuex'
import { formatPrice } from '@/functions/formatNumber'

import UITableResponsive from '@/components/UI/Table/Responsive.vue'
import UITabs from '@/components/UI/Tabs.vue'

const tabs = ['Actieve gebruikers', 'Inactieve gebruikers']
const tab = ref(0)

const store = useStore()
const axios = inject('axios')

const list = ref([])
const count = ref(0)

const filters = ref({
  limit: 20,
  offset: 0,
})

onMounted(() => getData())

const getData = async () => {
  const { data } = await axios.get('/api/dashboard/afrekenen/cash-in-omloop', filters.value)
  if (data.list) list.value = data.list
  if (data.count) count.value = data.count
  return data
}

const filteredItems = computed(() => {
  const records = list.value.filter(item => store.getters.chauffeur(item.user_id)?.is_active == (tab.value === 0))

  return {
    chauffeurs: records.filter(item => store.getters.chauffeur(item.user_id)?.type !== 'onderaannemer'),
    onderaannemers: records.filter(item => store.getters.chauffeur(item.user_id)?.type === 'onderaannemer'),
  }
})
</script>
